import React from "react"
import { Box, Heading, ResponsiveContext } from "grommet"
import { useTranslation } from "react-i18next"

const H2 = ({
  children,
  textAlign = "center",
  color = "black",
  spacer = false,
  margin = { top: "none", bottom: "xsmall" },
  ...rest
}) => {
  const size = React.useContext(ResponsiveContext)
  const { t } = useTranslation()

  const styling = {
    fontWeight: "700",
    textAlign: textAlign,
    lineHeight: "1.2",
    letterSpacing: "-0.025em",
    maxWidth: "100%",
  }

  return (
    <>
      <Heading
        level="2"
        size={size === "large" ? "large" : "medium"}
        color={color}
        margin={margin}
        style={{ ...styling }}
        {...rest}
      >
        {t(children)}
      </Heading>
      {spacer && <Box pad="medium" />}
    </>
  )
}

export default H2

import React from "react"
import styled from "styled-components"
import { Paragraph } from "grommet"
import { useTranslation } from "react-i18next"

const Title = styled(Paragraph)`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
`

const FooterTitle = ({ color = "white", children, size, ...rest }) => {
  const { t } = useTranslation()

  return (
    <Title
      color={color}
      {...rest}
      margin={
        size === "small"
          ? { top: "medium", bottom: "small" }
          : { top: "xsmall", bottom: "medium" }
      }
    >
      {t(children)}
    </Title>
  )
}

export default FooterTitle

import React from "react"
import { Heading } from "grommet"
import { useTranslation } from "react-i18next"

const H1 = ({
  children,
  color = "brand",
  size = "medium",
  margin = { vertical: "xsmall", left: "-3px" },
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Heading level="1" size={size} color={color} margin={margin} {...rest}>
      {t(children)}
    </Heading>
  )
}

export default H1

import React from "react"
import { Paragraph } from "grommet"
import { useTranslation } from "react-i18next"

const Label = ({
  children,
  color = "brand",
  alignSelf = "start",
  margin = { top: "none", bottom: "xsmall" },
  textAlign = alignSelf,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Paragraph
      color={color}
      margin={margin}
      alignSelf={alignSelf}
      style={{
        fontSize: "14px",
        textTransform: "uppercase",
        fontWeight: 800,
        textAlign: textAlign,
        letterSpacing: "0.15em",
      }}
      {...rest}
    >
      {t(children)}
    </Paragraph>
  )
}

export default Label

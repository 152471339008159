import React from "react"
import { Paragraph, ResponsiveContext } from "grommet"
import { useTranslation } from "react-i18next"
import { isObject } from "grommet/utils"

const colorPick = {
  dark: "paragraphDark",
  light: "paragraphLight",
  footer: "#B1B4C0",
}

const P = ({
  children,
  color = "dark",
  textAlign = "left",
  bold = false,
  margin = { top: "none", bottom: "xsmall" },
  translate = true,
  ...rest
}) => {
  const { t } = useTranslation()
  const size = React.useContext(ResponsiveContext)

  const calculateSize = size === "large" ? "16px" : "14px"

  const fontWeight = bold ? 500 : 400

  const styling = {
    textAlign: textAlign,
    fontSize: calculateSize,
    fontWeight: fontWeight,
    lineHeight: "1.75",
    maxWidth: "100%",
  }

  const destructObject = isObject(children) ? children.props.children : children

  return (
    <Paragraph
      color={colorPick[color] ? colorPick[color] : color}
      margin={margin}
      style={{ ...styling }}
      {...rest}
    >
      {translate ? t(destructObject) : children}
    </Paragraph>
  )
}

export default P

import React from "react"
import { Box } from "grommet"

import { H2, Label, P } from "./index"

const SectionTitle = ({
  ascending = true,
  title,
  subtitle,
  desc,
  size,
  variant = "dark",
  textAlign = "left",
  spacer = false,
  ...rest
}) => {
  const bpAlignment = size === "small" ? "center" : textAlign

  return (
    <Box gap="xsmall" direction={ascending ? "column" : "column-reverse"}>
      {spacer && (
        <Box
          background="brand"
          alignSelf="center"
          margin={{ bottom: "16px" }}
          style={{ height: "2px", width: "46px" }}
        />
      )}
      {subtitle && <Label alignSelf={bpAlignment}>{subtitle}</Label>}
      <H2
        color={variant === "dark" ? "dark-1" : "white"}
        textAlign={bpAlignment}
        {...rest}
      >
        {title}
      </H2>
      {desc && (
        <P color={variant} textAlign={bpAlignment}>
          {desc}
        </P>
      )}
    </Box>
  )
}

export default SectionTitle

import React from "react"
import { Heading } from "grommet"
import { useTranslation } from "react-i18next"

const H3 = ({
  children,
  margin = { top: "none", bottom: "xsmall" },
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Heading level="3" margin={margin} {...rest}>
      {t(children)}
    </Heading>
  )
}

export default H3

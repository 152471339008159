import React from "react"
import { Box, ResponsiveContext } from "grommet"

const Wrapper = ({
  children,
  top = "xlarge",
  bottom = "xlarge",
  horizontal = "small",
  ...rest
}) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        pad={{ top: top, bottom: bottom, horizontal: horizontal }}
        style={{ zIndex: 2 }}
      >
        <Box pad={size !== "large" ? "medium" : "0"} {...rest}>
          {children}
        </Box>
      </Box>
    )}
  </ResponsiveContext.Consumer>
)

export default Wrapper

import React from "react"
import { Box } from "grommet"

import Wrapper from "../components/Container/Wrapper"
import { H1, P } from "../components/Typography"

const Landing = ({
  size,
  section = "landing",
  subtitle = true,
  height = "medium",
}) => {
  return (
    <Wrapper>
      <Box
        margin={height === "small" ? {} : { bottom: "xlarge", top: "xlarge" }}
      >
        <Box width="600px" pad={{ bottom: "large" }}>
          <H1
            size="large"
            color="white"
            style={{ fontWeight: "900", maxWidth: "65%" }}
          >
            {`${section}:title`}
          </H1>

          <P
            color="light"
            size={size === "small" ? "medium" : "large"}
            style={size === "small" ? { maxWidth: "80%" } : null}
          >
            {subtitle ? `${section}:subtitle` : null}
          </P>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default Landing
